<!--
 * @Descripttion : 角色管理
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-08-31 20:15:15
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-14 10:30:57
-->
<template>
  <div>
    <div style="padding:10px 0;">
      <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
        <a-form-model-item>
          <a-input-search v-model="formInline.keyWord" placeholder="角色名称" allowClear enter-button
            @search="getRoleList" />
        </a-form-model-item>
        <a-form-model-item v-if="permissions.indexOf('sys:role:add') > -1">
          <a-button type="primary" icon="plus" @click="addRole" />
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-table :columns="columns" bordered :data-source="data" :rowKey='record => record.id' :pagination="pagination"
      :loading="loading" @change="handleTableChange" size="middle">



      <template slot="typeName" slot-scope="record">
        <div>{{ record.type == 1 ? '系统角色' : '供应商角色' }}</div>
      </template>

      <template slot="operation" slot-scope="record">
        <a-space>
          <a-button type="primary" size="small" icon="edit" v-if="permissions.indexOf('sys:role:edit') > -1"
            @click="editRole(record)" />
          <a-button type="danger" size="small" icon="delete" v-if="permissions.indexOf('sys:role:del') > -1"
            @click="delRole(record)" />
        </a-space>
      </template>
    </a-table>

    <!-- 新增/编辑角色 -->
    <a-drawer :title="title" :width="300" :visible="visibleRole" @close="onClose">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item label="角色" prop="name">
          <a-input v-model="form.name" placeholder="请输入角色" />
        </a-form-model-item>

        <a-form-model-item label="" prop="">
          <a-radio-group name="radioGroup" v-model="form.type">
            <a-radio :value="1">系统角色</a-radio>
            <a-radio :value="2">供应商角色</a-radio>
          </a-radio-group>
        </a-form-model-item>


        <a-form-model-item label="描述" prop="description">
          <a-input v-model="form.description" placeholder="请输入描述" type="textarea"
            :auto-size="{ minRows: 3, maxRows: 5 }" />
        </a-form-model-item>
        <a-form-model-item label="权限" prop="permissionIdArray">
          <div class="ml_tree_height">
            <a-tree v-model="form.permissionIdArray" checkable :tree-data="treeData" :replaceFields="replaceFields"
              blockNode @check="onCheck" :defaultExpandedKeys="[0]" />
          </div>
        </a-form-model-item>
        <div class="ml_drawer_footer">
          <a-button @click="onClose">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="onSubmit">
            确定
          </a-button>
        </div>
      </a-form-model>
    </a-drawer>
  </div>
</template>
<script>
import {
  addRole,
  delRole,
  getRole,
  updateRole,
  getRoleList,
  getPermissionAll,
  getPermissionByRoleID
} from "@/api/system";

import { mapState } from "vuex";

const columns = [
  { title: "角色", dataIndex: "name", align: "center" },
  {
    title: "角色类型",
    scopedSlots: { customRender: "typeName" },
    align: "center"
  },
  { title: "描述", dataIndex: "description", align: "center" },
  { title: "修改时间", dataIndex: "updateTime", align: "center" },
  {
    title: "操作",
    scopedSlots: { customRender: "operation" },
    align: "center"
  }
];
let treeData = [
  {
    id: 0,
    name: "root",
    child: []
  }
];
export default {
  data() {
    return {
      columns,
      data: [],
      loading: false,
      pagination: {
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40"],
        showSizeChanger: true,
        current: 1,
        total: 0
      },
      visibleRole: false, //  是否显示操作
      form: {
        id: "",
        name: "",
        description: "",
        permissionIds: [],
        permissionIdArray: [],
        permissionIdParentArray: [],
        type: 1,
      },
      rules: {
        name: [{ required: true, message: "请输入角色!" }],
        permissionIdArray: [{ required: true, message: "请选择权限!" }]
      },
      title: "",
      formInline: {
        keyWord: ""
      },
      treeData,
      replaceFields: { children: "child", title: "name", key: "id" },
      checkedKeys: [],
      menuData: [] //  菜单数组
    };
  },
  computed: {
    ...mapState({
      permissions: state => state.userStore.permissions
    })
  },
  created() {
    this.getRoleList();

    getPermissionAll({
      _: new Date().getTime()
    }).then(res => {
      treeData[0].child = res.data;
    });
  },
  methods: {
    getRoleList() {
      const that = this;
      that.loading = true;
      getRoleList({
        keyWord: that.formInline.keyWord || null,
        pageNum: that.pagination.current,
        pageSize: that.pagination.pageSize,
        _: new Date().getTime()
      })
        .then(res => {
          that.loading = false;
          that.data = res.data.list;
          that.pagination.total = res.data.total;
        })
        .catch(err => {
          console.log(err);
          that.loading = false;
        });
    },
    onClose() {
      this.visibleRole = !this.visibleRole;
    },
    onSubmit() {
      // 提交角色
      const that = this;
      that.form.permissionIds = [];
      that.form.permissionIds = that.form.permissionIds
        .concat(that.form.permissionIdArray)
        .concat(that.form.permissionIdParentArray);

      if (that.form.permissionIds.length > 0) {
        that.form.permissionIds = that.form.permissionIds.sort();
      }

      that.$refs.ruleForm.validate(valid => {
        if (valid) {
          that.form["json"] = true;

          if (that.form.id) {
            updateRole(that.form).then(() => {
              that.$message.success({
                content: "修改成功！",
                duration: 1,
                onClose: () => {
                  that.visibleRole = false;
                  that.getRoleList();
                }
              });
            });
          } else {
            addRole(that.form).then(() => {
              that.$message.success({
                content: "添加成功！",
                duration: 1,
                onClose: () => {
                  that.visibleRole = false;
                  that.getRoleList();
                }
              });
            });
          }
        }
      });
    },
    addRole() {
      // 新增角色
      const that = this;
      that.title = "新增角色";
      that.form.id = "";
      that.visibleRole = !that.visibleRole;
      that.form.permissionIdParentArray = [];
      that.form.permissionIdArray = [];
      that.form.permissionIds = [];
      that.$nextTick(() => {
        that.$refs.ruleForm.resetFields();
      });
    },
    editRole(e) {
      // 编辑角色
      const that = this;
      that.title = "编辑角色";
      that.visibleRole = !that.visibleRole;
      that.form.permissionIdParentArray = [];
      that.form.permissionIdArray = [];
      that.form.permissionIds = [];

      that.$nextTick(() => {
        that.$refs.ruleForm.resetFields();
      });
      getRole(e.id, {
        _: new Date().getTime()
      }).then(res => {
        that.form.id = res.data.id;
        that.form.name = res.data.name;
        that.form.description = res.data.description;
        that.form.type = res.data.type;

      });

      getPermissionByRoleID({
        roleId: e.id,
        _: new Date()
      }).then(res => {
        that.menuData = res.data;
        that.menuData.forEach(element => {
          //   that.form.permissionIds.push(element.id);
          if (element.parentId == 0) {
            that.form.permissionIdParentArray.push(element.id);
          } else {
            that.form.permissionIdArray.push(element.id);
          }
        });
      });
    },
    delRole(e) {
      // 删除角色
      const that = this;
      that.$confirm({
        title: "是否确认删除该角色?",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          delRole(e.id).then(() => {
            that.$message.success({
              content: "删除成功！",
              duration: 1,
              onClose: () => {
                that.getRoleList();
              }
            });
          });
        },
        onCancel() {
          console.log("Cancel");
        }
      });
    },
    onCheck(checkedKeys, e) {
      console.log(checkedKeys, e.halfCheckedKeys);
      this.form.permissionIdArray = checkedKeys;
      //   父级节点 e.halfCheckedKeys
      this.form.permissionIdParentArray = e.halfCheckedKeys;
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination, filters, sorter);
      this.pagination = { ...pagination };
      this.getRoleList();
    }
  }
};
</script>
<style lang="scss" scoped>
.ml_tree_height {
  max-height: 300px;
  overflow: auto;
  background-color: #f0f0f0;
}
</style>